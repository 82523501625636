// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-yumgmbh-gatsby-theme-yum-storyblok-src-pages-storyblok-preview-js": () => import("./../../../node_modules/@yumgmbh/gatsby-theme-yum-storyblok/src/pages/storyblok-preview.js" /* webpackChunkName: "component---node-modules-yumgmbh-gatsby-theme-yum-storyblok-src-pages-storyblok-preview-js" */),
  "component---node-modules-yumgmbh-gatsby-theme-yum-storyblok-src-templates-storyblok-default-template-js": () => import("./../../../node_modules/@yumgmbh/gatsby-theme-yum-storyblok/src/templates/StoryblokDefaultTemplate.js" /* webpackChunkName: "component---node-modules-yumgmbh-gatsby-theme-yum-storyblok-src-templates-storyblok-default-template-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-demo-1-jsx": () => import("./../../../src/pages/demo/1.jsx" /* webpackChunkName: "component---src-pages-demo-1-jsx" */),
  "component---src-pages-demo-2-jsx": () => import("./../../../src/pages/demo/2.jsx" /* webpackChunkName: "component---src-pages-demo-2-jsx" */),
  "component---src-pages-demo-index-jsx": () => import("./../../../src/pages/demo/index.jsx" /* webpackChunkName: "component---src-pages-demo-index-jsx" */),
  "component---src-pages-demo-workspace-jsx": () => import("./../../../src/pages/demo/workspace.jsx" /* webpackChunkName: "component---src-pages-demo-workspace-jsx" */),
  "component---src-pages-downloadcenter-js": () => import("./../../../src/pages/downloadcenter.js" /* webpackChunkName: "component---src-pages-downloadcenter-js" */),
  "component---src-pages-form-bestellvorgang-neu-js": () => import("./../../../src/pages/form-bestellvorgang-neu.js" /* webpackChunkName: "component---src-pages-form-bestellvorgang-neu-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-magazin-js": () => import("./../../../src/pages/magazin.js" /* webpackChunkName: "component---src-pages-magazin-js" */),
  "component---src-templates-collection-template-js": () => import("./../../../src/templates/CollectionTemplate.js" /* webpackChunkName: "component---src-templates-collection-template-js" */),
  "component---src-templates-news-page-template-js": () => import("./../../../src/templates/NewsPageTemplate.js" /* webpackChunkName: "component---src-templates-news-page-template-js" */),
  "component---src-templates-ratings-template-js": () => import("./../../../src/templates/RatingsTemplate.js" /* webpackChunkName: "component---src-templates-ratings-template-js" */)
}

